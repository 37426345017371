var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-spacer'),_c('v-btn',{staticClass:"gradient-bg white--text",attrs:{"small":"","height":"25px","color":""},on:{"click":function($event){_vm.paymentdialog = true}}},[_c('strong',[_vm._v("Credits Balance : "+_vm._s(_vm.TotalCredits)+" -- "+_vm._s(_vm.value)+" ")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto",attrs:{"headers":_vm.transactionData,"items":_vm.transactionItems,"dense":"","height":_vm.height,"loading":_vm.transactionLoading,"items-per-page":100,"fixed-header":_vm.fixed,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"item.transaction_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.transaction_on))}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Transactions available")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.s3_key",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item.s3_key != null || item.s3_key != undefined)?_c('a',{on:{"click":function($event){return _vm.download_invoice(
                    ("https://prznceinvoice.s3.ap-south-1.amazonaws.com/" + (item.s3_key))
                  )}}},[_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-download ")])],1):_c('v-icon',_vm._g({attrs:{"small":"","color":"grey"}},on),[_vm._v("mdi-download")])]}}],null,true)},[(item.s3_key != null || item.s3_key != undefined)?_c('span',{staticClass:"white--text"},[_vm._v("Download Invoice")]):_c('span',{staticClass:"white--text"},[_vm._v("Invoice Not Available")])])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('RechargeDialog',{attrs:{"paymentdialog":_vm.paymentdialog},on:{"closedialog":function($event){_vm.paymentdialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }