<template>
  <div>
    <v-card flat>
      <v-row no-gutters class="mt-4">
        <v-col cols="12" md="12" sm="12" lg="12" xl="12">
          <v-toolbar dense elevation="0">
            <v-spacer />
            <v-btn
              small
              height="25px"
              @click="paymentdialog = true"
              color=""
              class="gradient-bg white--text"
              ><strong
                >Credits Balance : {{ TotalCredits }} -- {{ value }}
              </strong></v-btn
            >
            <!-- :class="
              TotalCredits <= 100
                ? 'red--text'
                : TotalCredits <= 500 && TotalCredits > 100
                ? 'green--text'
                : TotalCredits > 500
                ? 'primary--text'
                : ''
            " -->
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="transactionData"
          :items="transactionItems"
          dense
          :height="height"
          :loading="transactionLoading"
          :items-per-page="100"
          :fixed-header="fixed"
          loading-text="Loading... Please wait"
          class="overflow-y-auto"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.transaction_on`]="{ item }">
            <span v-text="get_date(item.transaction_on)"> </span>
          </template>
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Transactions available</v-alert
            >
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.s3_key`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  v-if="item.s3_key != null || item.s3_key != undefined"
                  @click="
                    download_invoice(
                      `https://prznceinvoice.s3.ap-south-1.amazonaws.com/${item.s3_key}`
                    )
                  "
                >
                  <v-icon v-on="on" small color="primary">mdi-download </v-icon>
                </a>
                <v-icon v-else small v-on="on" color="grey"
                  >mdi-download</v-icon
                >
              </template>
              <span
                v-if="item.s3_key != null || item.s3_key != undefined"
                class="white--text"
                >Download Invoice</span
              >
              <span v-else class="white--text">Invoice Not Available</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <RechargeDialog
      :paymentdialog="paymentdialog"
      @closedialog="paymentdialog = false"
    />
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  listTransactionByOrganisation,
  PrznceDashBoard,
} from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import RechargeDialog from "@/components/Dialogs/RechargeDialog.vue";
export default {
  props: {
    currentSwipedUser: Object,
  },
  components: {
    Overlay,
    RechargeDialog,
  },
  data: () => ({
    fixed: true,
    paymentdialog: false,
    overlay: false,
    transactionData: [
      { text: "Selected Pack", value: "pack_name" },
      { text: "Amount Paid", value: "transaction_amount" },
      { text: "Transaction Status", value: "transaction_status" },
      { text: "Transaction Date", value: "transaction_on" },
      { text: "Transaction By", value: "transaction_by" },
      { text: "Invoice", value: "s3_key" },
    ],
    transactionItems: [],
    transactionLoading: false,
    TotalCredits: "",
    value: "",
    height: 0,
  }),
  created() {
    this.getDashboardData();
    this.height = window.innerHeight - 210;
    this.overlay = true;
    this.list_transactions();
  },
  watch: {
    currentSwipedUser(val) {
      console.log("asdfghjkl;", val);
      this.TotalCredits = val.totalcredits;
      this.$forceUpdate();
    },
    TotalCredits(val) {
      this.value = "";
      if (val <= 100) {
        return (this.value = "Recharge Now");
      } else if (val > 100 && val <= 500) {
        return (this.value = "Recharge Soon");
      } else if (val > 500) {
        return (this.value = "Recharge");
      }
    },
  },
  methods: {
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    download_invoice(item) {
      window.open(item);
    },
    async list_transactions() {
      this.overlay = true;
      this.transactionLoading = true;
      try {
        await API.graphql(
          graphqlOperation(listTransactionByOrganisation, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((res) => {
          this.transactionItems = res.data.listTransactionByOrganisation.data;
          // console.log("ie", this.transactionItems);
          this.transactionLoading = false;
          this.overlay = false;
        });
      } catch (err) {
        console.log(err);
        this.overlay = false;
        this.transactionLoading = false;
      }
    },
    async getDashboardData() {
      try {
        await API.graphql(
          graphqlOperation(PrznceDashBoard, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((res) => {
          if (res.data.PrznceDashBoard != null) {
            this.TotalCredits = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_credits;
          } else {
            this.overlay = false;
          }
        });
        this.$forceUpdate();
      } catch (err) {
        this.overlay = false;
        console.log(err);
      }
    },
  },
};
</script>

<style></style>
